<template>
  <div class="yellow-background">
        <div class="image">
            <img :src="path" alt="Imagem Background">  
        </div>
        <div class="back"></div>
        <div class="quote">
            <h1>“Para ser solidário não precisa ser religioso, tem que ser humano”</h1>
            <p>- Padre Júlio Lancellotti</p>
        </div>
  </div>
</template>

<script>

import VueLazyload from 'vue-lazyload';

export default {
  name: 'frasepadre',
  data(){
    return {
        path: require('../../assets/Home/backgroundFrase.jpg'),
        // path: require('../../assets/Home/Banners/banner1.jpg'),
    }
  },
  directives: {
    'lazy': VueLazyload.directive
  }
}

</script>

<style lang="scss" scoped>

.yellow-background {
    height: 18rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-top: 2px solid var(--preto);
    border-bottom: 2px solid var(--preto);

    .image {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .back {
        position: absolute;
        background: #FCD94A99;
        z-index: 5;
        width: 100%;
        height: 100%;
    }

    .quote {
        position: absolute;
        text-align: center;
        width: 36rem;
        z-index: 10;

        h1 {
            font-weight: 600;
            font-size: 2rem;
        }

        p {
            margin: 1rem;
            font-size: 1rem;
        }
    }

    @media (max-width: 48rem) {
        height: 7rem;
        border-top: 1px solid var(--preto);
        border-bottom: 1px solid var(--preto);

        .quote {
            width: 18rem;

            h1 {
                font-size: 1rem;
            }

            p {
                margin: 0.5rem;
                font-size: 0.75rem;
            }
        }
    }
}

</style>
