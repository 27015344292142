<template>
    <div class="docs">
        <h1>Documentário</h1>
        <div class="box1">
            <p>Gravamos um documentário para <span>destacar os sonhos das pessoas em situação de rua e vulnerabilidade social que ajudamos</span>. A narrativa gira em torno das histórias de Ana Maria, Cosmo, Adenor e Geraldo, que compartilham seus sonhos e realidades, que são concretas e complexas, mas tão palpáveis quanto os sonhos que nos impulsionam e fortalecem para lidar com a vida real.</p>
        </div>
        <div class="image">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/edrgnTdEPEI" title="Vídeo Institucional - ONG da Rua" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <!-- <div class="embreve">
            <h2>EM BREVE</h2>
            <img src="../../assets/Home/embreve.png" alt="Imagem Documentário">
        </div> -->
        <div class="box2">
            <p>Através deste documentário, damos voz a essas histórias, mostrando que <span>por trás de cada rosto há um sonho, uma luta e uma esperança</span>. Convidamos o público a olhar além das aparências e enxergar a humanidade que nos une, celebrando a força interior que nos faz perseverar. Cada cena é um convite à reflexão e à empatia, lembrando-nos que todos temos sonhos e que, <span>juntos, podemos criar um mundo onde cada pessoa tenha a oportunidade de realizá-los</span>.</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'documentario'
}

</script>

<style lang="scss" scoped>

.docs {
    background-color: var(--preto);
    color: var(--branco);
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;

    h1 {
        font-family: var(--font1);
        font-weight: 400;
        font-size: 3.5rem;
        text-transform: uppercase;
    }

    p {
        text-align: center;
        font-family: var(--font2);
        font-weight: 300;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    span {
        font-weight: 800;
    }

    .box1 {
        max-width: 36rem;
    }

    .image {
        width: 42rem;
        height: 24rem;
        background-color: var(--amarelo);
    }

    // .embreve {
    //     width: 42rem;
    //     height: 24rem;
    //     border: 2px solid var(--preto);
    //     border-radius: 0.5rem;
    //     display: flex;
    //     justify-content: center;
    //     overflow: hidden;
    //     position: relative;

    //     img {
    //         opacity: 0.3;
    //     }

    //     h2 {
    //         color: var(--amarelo);
    //         font-size: 6rem;
    //         white-space: nowrap;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%) rotate(-20deg); 
    //         z-index: 5;
    //     }
    // }

    .box2 {
        background-color: var(--bege);
        color: var(--preto);
        max-width: 42.5rem;
        padding: 1.5rem;
        border: 2px solid var(--preto);
        border-radius: 0.5rem;
    }

    @media (max-width: 48rem) {
        padding: 2rem;
        gap: 1rem;

        h1 {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }

        p {
            font-size: 0.75rem;
            line-height: 1rem;
        }

        // .embreve {
        //     width: 28rem;
        //     height: 16rem;

        //     h2 {
        //         font-size: 4rem;
        //     }
        // }

        .image {
            width: 28rem;
            height: 16rem;
        }

        .box2 {
            background-color: var(--preto);
            color: var(--branco);
            padding: 0rem;
        }
    }

    @media (max-width: 30rem) {
        h1 {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        .image {
            width: 17rem;
            height: 10rem;
        }

        // .embreve {
        //     width: 17rem;
        //     height: 10rem;

        //     h2 {
        //         font-size: 2.5rem;
        //     }
        // }
    }
}

</style>