<template>
    <div class="donation-data">
        <img :src="path" alt="Imagem Background">  
        <div class="content">
            <h1>A Ong da Rua em números</h1>
            <h3 v-if="!isMobile">Esse são os números de pessoas auxiliadas, doações e saídas realizadas ao longo de nossa história de 2020. E seguimos contando🙏</h3>
            <div class="grid-container" ref="trigger">      
                <div v-for="(item, i) in items" :key="i" class="grid-item">
                    <h2>+{{item.contStart}}</h2>
                    <p>{{item.text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import VueLazyload from 'vue-lazyload';

export default {
    name: 'ongnumeros',
    props: {
        isMobile: 'isMobile',
        options: {
            type: Object,
            default: {root: null, threshold: '0'}
        }
    },
    data(){
        return {
            items: [
                {contStart: 0, contFinal: 300, text: 'Pessoas auxiliadas'},
                {contStart: 0, contFinal: 100, text: 'Saídas realizadas'},
                {contStart: 0, contFinal: 1900, text: 'Marmitas'},
                {contStart: 0, contFinal: 360, text: 'Cestas básicas'},
                {contStart: 0, contFinal: 360, text: 'Cobertores'},
                {contStart: 0, contFinal: 940, text: 'Kits de higiene'},
                {contStart: 0, contFinal: 360, text: 'Kits de material escolar'},
                {contStart: 0, contFinal: 280, text: 'Brinquedos'},
            ],
            path: require('../../assets/background.png'),
            observer: null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(entries => {
            this.startCounter(entries[0]);
        }, this.options);
        this.observer.observe(this.$refs.trigger);
    },
    methods: {
        startCounter() {
            this.items.forEach(item => {
                const increment = item.contFinal / 100;
                let currentValue = 0;

                let intervalId = setInterval(() => {
                    if (currentValue < item.contFinal) {
                        currentValue += increment;
                        item.contStart = Math.floor(currentValue);
                    } else {
                        item.contStart = item.contFinal;
                        clearInterval(intervalId);
                    }
                }, 10);
            }); 
        }
    },
    directives: {
        'lazy': VueLazyload.directive
    }
}

</script>

<style lang="scss" scoped>

.donation-data {
    width: 100%;
    height: 46rem;
    padding: 6rem 0rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-color: var(--cinza);

    img {
        margin-top: 4rem;

        @media (min-width: 71rem) {
            margin-top: 7rem;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        top: 6rem;
        height: 100%;
        position: absolute;
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-family: var(--font1);
            font-weight: 400;
            font-size: 3.5rem;
            text-transform: uppercase;
        }

        h3 {
            margin-top: 2rem;
            font-weight: 400;
            width: 40rem;
        }

        .grid-container {
            display: grid;
            margin-top: 4rem;
            grid-template: repeat(2, 1fr) / repeat(4, 1fr);
            gap: 2.5rem;
            align-content: center;
            align-items: center;
            justify-items: center;

            .grid-item {
                background: var(--amarelo);
                width: 13rem;
                height: 8.5rem;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                border: 2px solid var(--preto);
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4); 

                h2 {
                    font-family: var(--font1);
                    font-size: 3rem;
                }

                p {
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
        }
    }

    @media (max-width: 64rem) {
        height: 64rem;

        img {
            margin-top: 2rem;
        }

        .content {
            h1 {
                font-size: 2.5rem;
            }

            .grid-container {
                grid-template: repeat(4, 1fr) / repeat(2, 1fr);
            }
        }
    }

    @media (max-width: 48rem) {
        height: 32rem;
        padding: 0rem;

        img {
            margin-top: -2rem;
        }

        .content {
            top: 2rem;

            h1 {
                width: 14rem;
                font-size: 1.5rem;
                line-height: 1.5rem;
            }

            .grid-container {
                margin-top: 3rem;
                gap: 1rem;

                .grid-item {
                    width: 7.5rem;
                    height: 4.5rem;
                    padding: 0.25rem;

                    h2 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

</style>