<template>
    <div class="link dropdownside" @click="toggleMenu">
        <div class="item-menu">
            <router-link :to="route">{{ title }}</router-link>
            <img v-show="!isOpen" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
            <img v-show="isOpen" src="../../assets/Header/arrowUp.svg" alt="Seta para Cima">
        </div>
        <transition name="fade" appear="">
            <div class="item-sub-menu" v-if="isOpen">
                <div v-for="(item, i) in items" :key="i" class="refs">
                    <router-link :to="{ path: item.path, hash: item.hash }">{{ item.title }}</router-link>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'dropdownlateral',
    props: ['title', 'route', 'items'],
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
        }
    }
}

</script>

<style lang="scss" scoped>

.dropdownside {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    white-space: nowrap;

    .item-menu {
        flex-grow: 1;
    }

    .item-menu img {
        width: 1.25rem;
        height: 0.625rem;
        margin-left: 0.5rem;
    }

    .item-sub-menu {
        font-size: 1rem;
        padding: 0rem 0.25rem;
        
        .refs {
            padding: 0.125rem 0rem;
            display: flex;

            a {
                flex-grow: 1;
            }
        }
    }
    
    a {
        text-decoration: none;
        color: var(--preto) ;
    }
}

</style>