<template>
  <div @click="scrollToTop" class="btn">
    <img src="../assets/Header/arrowUp.svg" alt="Seta para Cima">
  </div>
</template>

<script>

export default {
    name: 'scrolltopo',
    methods: {
        scrollToTop() {
            const topPosition = 0;
            window.scrollTo({
                top: topPosition,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.btn {
    position: absolute;
    z-index: 1000;
    bottom: 12rem;
    right: 1.25rem;
    width: 5rem;
    height: 5rem;
    background-color: var(--amarelo);
    border: 2px solid var(--preto);
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4); 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 3rem;
        height: 1.5rem;
    }

    @media (max-width: 48rem) {
        bottom: 6rem;
        width: 2rem;
        height: 2rem;
        border: 1px solid var(--preto);

        img {
            height: 0.75rem;
        }
    }
}

</style>
