<template>
    <div class="carousel">
        <h1>Comentário das pessoas que auxiliamos</h1>
        <div class="carousel-inner">
            <img @click="prev" src="../../assets/Header/arrowLeft.svg" class="prev" :class="{'disabled': currentIndex === 0}" alt="Previous">
            <div class="comment">
                <p>{{ comments[currentIndex].text }}</p>
                <h3>{{ comments[currentIndex].author }}</h3>
            </div>
            <img @click="next" src="../../assets/Header/arrowRight.svg" class="next" :class="{'disabled': currentIndex === comments.length-1}" alt="Next">
        </div>
    </div>
</template>

<script>

export default {
    name: 'carouselcomments',
    data() {
        return {
        currentIndex: 0,
        comments: [
            {text: '“O trabalho da OnG ajuda muito a gente porque conseguimos os mantimentos!"',  
            author: 'Gerson' },
            {text: '“A ajuda de vocês é super importante no nosso dia a dia! Vocês são uns anjos!"', 
            author: 'Dona Nilsa' },       
            {text: '“A OnG é 10 de 10!"', 
            author: 'Dona Maria' },
        ]
        };
    },
    methods: {
        next() {
            if (this.currentIndex < this.comments.length - 1) {
                this.currentIndex++;
            }
        },
        prev() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        }
    }
}

</script>

<style lang="scss" scoped>

.carousel {
    width: 100%;
    padding: 6rem 0.5rem 8rem 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: var(--font1);
        font-size: 3.5rem;
        font-weight: 400;
        text-transform: uppercase;
        width: 58rem;
        line-height: 4rem;
        margin-bottom: 4rem;
    }

    .carousel-inner {
        margin: auto;
        display: flex;
        justify-content: center;
        width: 90%;
        max-width: 48rem;
        min-height: 5.5rem;
        position: relative;

        img {
            position: absolute;
            height: 3rem;
            cursor: pointer;
            top: 1rem;
        }

        .prev {
            left: 0;
        }

        .next {
            right: 0;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .comment {
            padding: 0rem 6rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                opacity: 0.8;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 600;
                margin-top: 1rem;
            }
        }
    }

    @media (max-width: 64rem) {
        h1 {
            width: 40rem;
            font-size: 2.5rem;
            line-height: 2.5rem;
            margin-bottom: 2rem;
        }
    }

    @media (max-width: 48rem) {
        padding: 2rem 0.5rem 4rem 0.5rem;

        h1 {
            width: 20rem;
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;
        }

        .carousel-inner {
            min-height: 3rem;

            img {
                height: 1.5rem;
            }

            .comment{
                padding: 0rem 2rem;

                p {
                    font-size: 0.625rem;
                }

                h3 {
                    font-size: 0.75rem;
                }
            }
        }
    }
}

</style>
