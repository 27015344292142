<template>
  <div class="btn">
    <router-link  to="/noisvoce#doacoesfinanceiras">Doe agora</router-link>
  </div>
</template>

<script>

export default {
  name: 'DoeAgora'
}

</script>

<style lang="scss" scoped>

.btn a {       
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  padding: 10px 20px;
  border-radius: 31px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4); 
  color: var(--amarelo);
  background-color: var(--preto);
  cursor: pointer;

  @media (max-width: 48rem) {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
}


</style>
