<template>
    <footer>
        <div class="footer-image">
            <img src="../assets/logo.png" alt="Ong da Rua">
        </div>
        <div class="footer-text">
            <p>“Reconhecendo privilégios e transformando em responsabilidades.”</p>
            <p>CNPJ: 50.570.700/0001-90</p>
        </div>
        <div class="footer-right">
            <DoeAgora />
        </div>
    </footer>
</template>

<script>

import DoeAgora from './DoeAgora.vue';

export default {
    name: 'rodape',
    components: {
        DoeAgora
    }
}

</script>

<style lang="scss" scoped>

footer {
    width: 100%;
    background-color: var(--amarelo);
    border: 2px solid var(--preto);
    color: var(--preto);
    padding: 0.75rem 1.25rem;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-items: center;
    column-gap: 0.75rem;
    
    .footer-image {
        grid-column: 2;
        grid-row: 1;

        img {
            width: 4.375rem;
        }
    }

    .footer-text {
        grid-column: 2;
        grid-row: 2;
        text-align: center;
        font-size: 0.75rem;
        min-width: 10rem;

        p {
            margin: 0.25rem 0rem;
        }
    }

    .footer-right {
        grid-column: 3;
        grid-row: span 2;
        justify-self: end;
    }

    @media (max-width: 48rem) {
        border: 1px solid var(--preto);
        column-gap: 0.25rem;

        .footer-image {
            grid-column: 1;
            justify-self: start;

            img {
                width: 2.375rem;
            }
        }

        .footer-text {
            grid-row: 1;
            font-size: 0.5rem;
        }
    }

    @media (max-width: 22rem) {
        padding: 0.75rem;
    }
}

</style>