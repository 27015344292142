<template>
    <header>
        <nav>
            <div class="logo">
                <router-link to="/">
                    <img src="../../assets/logo.png" alt="Ong da Rua">
                </router-link>
            </div>
            <div class="items" v-show="!mobile">
                <MenuTopo />
            </div>
            <DoeAgora class="btn-doe" v-show="!mobile"/>
            <div class="insta">
                <a href="https://www.instagram.com/ongdarua/" target="_blank" rel="noopener noreferrer">
                    <img src="../../assets/Header/instagramIcon.png" alt="Instagram">
                </a>
            </div>
            <div class="icon" @click="toggleMobileNav" v-show="mobile">
                <img v-show="!mobileNav" src="../../assets/Header/openMenu.svg" alt="Seta para Baixo">
                <img v-show="mobileNav" src="../../assets/Header/closeMenu.svg" alt="Seta para Cima" class="menor">
            </div>
            <transition name="mobile-nav">
                <MenuLateral v-show="mobileNav"/>
            </transition>
        </nav>
    </header>
</template>

<script>

import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import DoeAgora from '../DoeAgora.vue';
import MenuTopo from './MenuTopo.vue';
import MenuLateral from './MenuLateral.vue';

export default {
  name: 'Header',
  components: {
    DoeAgora,
    MenuTopo,
    MenuLateral
  },
  setup() {
    const mobile = ref(null);
    const mobileNav = ref(false);
    const router = useRouter();

    const checkScreen = () => {
      if (window.innerWidth <= 1136) {
        mobile.value = true;
        return;
      }
      mobile.value = false;
      mobileNav.value = false;
      return;
    };

    const toggleMobileNav = () => {
      mobileNav.value = !mobileNav.value;
    };

    const updateMobileNav = () => {
      mobileNav.value = false;
    };

    onMounted(() => {
      window.addEventListener('resize', checkScreen);
      checkScreen();
      router.afterEach(updateMobileNav);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreen);
      router.afterEach(updateMobileNav);
    });

    return {
      mobile,
      mobileNav,
      toggleMobileNav
    };
  }
}

</script>

<style lang="scss">

header {
    background-color: var(--amarelo);
    width: 100%;
    color: var(--preto);

    nav {
        border: 2px solid var(--preto);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.25rem;
        padding: 1rem 1.25rem;       
        transition: 0.5s ease all;
        height: 100%;
        position: relative;

        .logo img {
            width: 4.5rem;
        }

        .insta img {
            display: block;
            width: 2.5rem;
        }

        .icon {
            width: 2.5rem;

            img {
                width: 100%;
                display: block;
                margin: auto;
                cursor: pointer;
                transition: .8s ease all;
            }

            .menor {
                width: 70%;
            }
        }

        @media (max-width: 71rem) {
            justify-content: flex-end;
            gap: 0.625rem;

            .logo {
                position: absolute;
                left: 1.25rem;
            }

            .logo img {
                width: 3.75rem;
            }
        }

        @media (max-width: 38rem) {
            padding: 1rem;

            .logo img {
                width: 2.5rem;
            }

            .insta img {
                width: 2rem;
            }

            .icon {
                width: 2rem;
            }
        }
    }

    @media (max-width: 48rem) {
        nav {
            border: 1px solid var(--preto);
        }
    }
}

</style>