<template>
    <div class="bemvindo">
        <h2>Salve, galera👋</h2>
        <h1>Bem-vindos a ong da rua!</h1>
        <p>Conheça nosso projeto e faça parte dessa comunidade que visa garantir cidadania e visibilidade a toda população.</p>
    </div>
</template>

<script>

export default {
    name: 'bemvindo'
}

</script>

<style lang="scss" scoped>

.bemvindo {
    background-color: var(--preto);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 6rem 2rem;
    color: var(--branco);

    h1 {
        color: var(--amarelo);
        font-family: var(--font1);
        font-weight: 400;
        font-size: 8rem;
        line-height: 1em;
        text-transform: uppercase;
        margin: 1.5rem 0rem;
        max-width: 69rem;

        @media (max-width: 77rem) {
            font-size: 6.5rem;
        }
    }

    h2 {
        font-size: 2rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    p {
        font-size: 1.4rem;
        max-width: 40rem;
        margin: auto;
    }

    @media (max-width: 64rem) {
        h1 {
            font-size: 4.5rem;
            max-width: 44rem;
            margin: 0.5rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        p {
            width: 29rem;
            font-size: 1rem;
        }
    }

    @media (max-width: 48rem) {
        padding: 2rem 1rem;

        h1 {
            font-size: 1.5rem;
            max-width: 12.5rem;;
        }

        h2 {
            font-size: 1rem;
        }

        p {
            width: 15rem;
            font-size: 0.75rem;
        }
    }
}

</style>