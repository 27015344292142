<template>
	<div class="listra" ref="listra">
		<div class="slide-track">
			<div v-for="(name, i) in names" :key="i" class="group">
				<p>{{ name }}</p>
				<img :src="person" alt="Pessoa" />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'listra',
	data() {
		return {
			names: [
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
				'comunidade',
				'humanidade',
				'cidadania',
				'ação social',
			],
			person: require('../assets/pessoa.png'),
		};
	},
}

</script>

<style lang="scss" scoped>

@keyframes scroll {
	0% {
		transform: translateX(-50%);
	}
	100% {
		transform: translateX(0%);
	}
}

.slide-track {
	animation: scroll 20s linear infinite;
	display: flex;
}

.listra {
	background-color: var(--amarelo);
	height: 3rem;
	padding: 0.4rem;
	border: 2px solid var(--preto);
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;

	.group {
		display: flex;
		align-items: center;
		height: 100%;

		img {
			filter: brightness(0%);
		}

		p {
			font-family: var(--font1);
			text-transform: uppercase;
			font-size: 1.5rem;
		}
	}

	@media (max-width: 48rem) {
		height: 2.5rem;
		border: 1px solid var(--preto);

		.group {
			img {
				height: 1.5rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
}

</style>
