import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Home' }
  },
  {
    path: '/sobrenois',
    name: 'sobrenois',
    meta: { title: 'Sobre nóis' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SobreNois.vue')
  },
  {
    path: '/noisvoce',
    name: 'noisvoce',
    meta: { title: 'Nóis e você' },
    component: () => import('../views/NoisVoce.vue')
  },
  {
    path: '/noissociedade',
    name: 'noissociedade',
    meta: { title: 'Nóis e a sociedade' },
    component: () => import('../views/NoisSociedade.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    meta: { title: 'Blog' },
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/blog/:postId',
    name: 'blogPost',
    meta: { title: 'Blog' },
    component: () => import('../views/Blog.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.title = to.meta.title || 'ONG da Rua';

    if(to.hash){
      return {      
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

export default router
