<template>
  <div id="home">
    <Banner />
    <BemVindo />
    <MVV />
    <Listra />
    <Documentario />
    <PerguntasFrequentes :isMobile="mobile"/>
    <FrasePadre />
    <OngNumeros :isMobile="mobile"/>
    <VenhaConhecer :isMobile="mobile"/>
    <InstagramPosts v-if="!mobile"/>
    <ComentariosExternos />
  </div>
</template>

<script>

import Banner from '../components/Home/Banner.vue';
import BemVindo from '../components/Home/BemVindo.vue';
import MVV from '../components/Home/MVV.vue';
import Listra from '../components/Listra.vue';
import Documentario from '../components/Home/Documentario.vue';
import PerguntasFrequentes from '../components/Home/PerguntasFrequentes.vue';
import FrasePadre from '../components/Home/FrasePadre.vue';
import OngNumeros from '../components/Home/OngNumeros.vue';
import VenhaConhecer from '../components/Home/VenhaConhecer.vue';
import InstagramPosts from '../components/Home/InstagramPosts.vue';
import ComentariosExternos from '../components/Home/ComentariosExternos.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      mobile: null,
    }
  },
  components: {
    Banner,
    BemVindo,
    MVV,
    Listra,
    Documentario,
    PerguntasFrequentes,
    FrasePadre,
    OngNumeros,
    VenhaConhecer,
    InstagramPosts,
    ComentariosExternos,
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenWidth);
    this.checkScreenWidth();
  },
  methods: {
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      this.mobile = screenWidth <= 768; 
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }
}
</script>

<style lang="scss" scoped>

#home {
  position: relative;
}

</style>
