<template>
  <div class="app">
    <Cabecalho />
    <div class="content">
      <router-view/>
    </div>
    <ScrollTopo />
    <Rodape />
  </div>
</template>

<script>

import Cabecalho from './components/Header/Cabecalho.vue';
import Rodape from './components/Rodape.vue';
import ScrollTopo from './components/ScrollTopo.vue';

export default {
  components: {
    Cabecalho,
    Rodape,
    ScrollTopo
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Inter:wght@100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --amarelo: #FCD94A;
  --bege: #FFF8DB;
  --cinza: #E5E5E5;
  --preto: #000;
  --branco: #fff;
  --hoverbege: #ffeca1;
  --font1: "Archivo Black", sans-serif;
  --font2: "Inter", sans-serif;
  --border-desktop: 2px solid var(--preto);
  --border-mobile: 1px solid var(--preto);
}

.app {
  position: relative;
}

</style>
