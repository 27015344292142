<template>
  <div class="banner">
    <transition name="slice" mode="out-in">
      <img :key="currentImage" :src="currentImage" alt="Banner" />
    </transition>
  </div>
</template>

<script>

import VueLazyload from "vue-lazyload";

export default {
  name: "banner",
  props: ['isMobile'],
  data() {
    return {
      images: [
        require("../../assets/Home/Banners/banner1.jpg"),
        // require("../../assets/Home/Banners/banner2.jpg"),
      ],
      imageIndex: 0,
    };
  },
  mounted() {
    this.startImageSlider();
  },
  methods: {
    startImageSlider() {
      setInterval(() => {
        if(this.imageIndex < this.images.length-1){
          this.imageIndex +=1
        } else {
          this.imageIndex = 0
        } 
      }, 5000);
    }
  },
  computed: {
    currentImage() {
      return this.images[this.imageIndex];
    },
  },
  directives: {
    'lazy': VueLazyload.directive,
  },
};
</script>

<style lang="scss" scoped>

.banner {
  width: 100%;
  max-height: 60rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    width: 100%;
    // max-width: 90rem;
  }

  @media (max-width: 48rem) {
    height: 22rem;
    overflow: hidden;
    
    img {
      width: 48rem;
    }
  }

  .slice-enter-active, .slice-leave-active {
    transition: all 0.5s ease;
  }

  .slice-enter-from, .slice-leave-to {
    opacity: 0;
  }
}

</style>