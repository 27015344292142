<template>
	<div class="container">
		<div v-for="(post, i) in posts" :key="i" class="images">
			<img :src="post" alt="Post Instagram" />
		</div>
	</div>
</template>

<script>

import VueLazyload from 'vue-lazyload';

export default {
	name: 'donationdata',
	data() {
		return {
			posts: [
				require('../../assets/Home/Posts/Post1.png'),
				require('../../assets/Home/Posts/Post2.png'),
				require('../../assets/Home/Posts/Post3.png'),
				require('../../assets/Home/Posts/Post4.png'),
				require('../../assets/Home/Posts/Post5.png'),
				require('../../assets/Home/Posts/Post6.png'),
				require('../../assets/Home/Posts/Post7.png'),
				require('../../assets/Home/Posts/Post8.png'),
				require('../../assets/Home/Posts/Post1.png'),
				require('../../assets/Home/Posts/Post2.png'),
				require('../../assets/Home/Posts/Post3.png'),
				require('../../assets/Home/Posts/Post4.png'),
				require('../../assets/Home/Posts/Post5.png'),
				require('../../assets/Home/Posts/Post6.png'),
				require('../../assets/Home/Posts/Post7.png'),
				require('../../assets/Home/Posts/Post8.png'),
				require('../../assets/Home/Posts/Post1.png'),
				require('../../assets/Home/Posts/Post2.png'),
				require('../../assets/Home/Posts/Post3.png'),
				require('../../assets/Home/Posts/Post4.png'),
				require('../../assets/Home/Posts/Post5.png'),
				require('../../assets/Home/Posts/Post6.png'),
				require('../../assets/Home/Posts/Post7.png'),
				require('../../assets/Home/Posts/Post8.png'),
			],
		};
	},
	directives: {
		lazy: VueLazyload.directive,
	},
}

</script>

<style lang="scss" scoped>

.container {
	display: flex;
	align-items: center;
	overflow: scroll;
	border-top: 2px solid var(--preto);
	border-bottom: 2px solid var(--preto);

	.images {
		height: 11.5rem;
		width: 11.5rem;

		img {
			height: 100%;
		}
	}
}

</style>
