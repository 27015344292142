<template>
    <div class="menu-top">
        <router-link class="link dif" to="/">Home</router-link>
        <router-link to="/sobrenois"><DropDownTopo title='Sobre nóis' route='/sobrenois' :items='sobrenois'/></router-link>
        <router-link to="/noisvoce"><DropDownTopo title='Nóis e você' route='/noisvoce' :items='noisvoce'/></router-link>
        <router-link to="/noissociedade"><DropDownTopo title='Nóis e a sociedade' route='/noissociedade' :items='noissociedade'/></router-link>
        <router-link class="link dif" to="/blog">Blog</router-link>
        <!-- <router-link class="link dif" to="/blog">Blog</router-link> -->
    </div>
</template>

<script>

import DropDownTopo from './DropDownTopo.vue';

export default {
    name: 'menutopo',
    components: {
        DropDownTopo
    },
    data() {
        return {
            mobile: null,
            mobileNav: null,      
            sobrenois: [
                {title: 'História', path: '/sobrenois', hash: '#historia'}, 
                {title: 'Frentes de atuação', path: '/sobrenois', hash: '#frentesdeatuacao'},
                {title: 'Locais de atuação', path: '/sobrenois', hash: '#locaisdeatuacao'}, 
                {title: 'Equipe', path: '/sobrenois', hash:'#equipe'},
                {title: 'Galeria de fotos', path: '/sobrenois', hash: '#galeria'}
            ],
            noisvoce: [
                {title: 'Como ajudar', path: '/noisvoce', hash: '#comoajudar'}, 
                {title: 'Contatos', path: '/noisvoce', hash: '#contatos'}
            ],
            noissociedade: [
                {title: 'Na mídia', path: '/noissociedade', hash: '#namidia'}, 
                {title: 'Parcerias', path: '/noissociedade', hash: '#parcerias'}
            ]
        }
    },
}

</script>

<style lang="scss" scoped>

.menu-top {
    padding: 0.625rem;
    gap: 0.25rem;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .link {
        color: var(--preto);
        font-size: 1.25rem;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
    }

    .dif {
        padding: 1rem 1.25rem;
        align-self: center;
    }

    a {
        color: var(--preto);
        text-decoration: none;
        z-index: 10;
    }
}

</style>