<template>
	<div class="mvv-container">
		<div class="grid">
			<div class="item line">
				<div class="image">
					<img src="../../assets/Home/Missao.png" alt="Missão Ong da Rua" />
				</div>
				<h1>Missão</h1>
				<p>
					Impactar a vida das pessoas em situação de rua, reintegrando-as e tornando
					suas histórias visíveis à sociedade.
				</p>
			</div>
			<div class="item line">
				<div class="image">
					<img src="../../assets/Home/Visao.png" alt="Visão Ong da Rua" />
				</div>
				<h1>Visão</h1>
				<p>
					Auxiliar na erradicação de situações de extrema vulnerabilidade no Distrito
					Federal.
				</p>
			</div>
			<div class="item dif">
				<div class="image">
					<img src="../../assets/Home/Valores.png" alt="Valores Ong da Rua" />
				</div>
				<h1>Valores</h1>
				<ul>
					<li>Diversidade cultural</li>
					<li>Consciência de classe</li>
					<li>Afeto</li>
					<li>Responsabilidade</li>
					<li>Trabalho em equipe</li>
					<li>Proatividade</li>
					<li>Produção de conhecimento</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'mvv'
}

</script>

<style lang="scss" scoped>

.mvv-container {
	width: 100%;
	padding: 6rem 0rem;
	background-color: var(--bege);

	.grid {
		margin: auto;
		display: flex;
		justify-content: center;
		width: 63rem;
		gap: 2rem;

		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.5rem;
			width: 100%;
			position: relative;

			h1 {
				font-family: var(--font1);
				font-weight: 400;
				text-transform: uppercase;
			}

			p {
				width: 12rem;
				text-align: center;
			}

			ul {
				list-style-position: inside;
			}
		}

		.line::after {
			content: '';
			background-color: var(--preto);
			position: absolute;
			width: 2px;
			height: 100%;
			top: 0;
			left: calc(100% + 7px);
		}

		@media (max-width: 69rem) {
			width: 48rem;

			.item {
				padding: 0rem;
			}
		}
	}

	@media (max-width: 48rem) {
		padding: 2rem 0rem;

		.grid {
			width: 16rem;
			flex-direction: column;
			align-items: center;
			gap: 1rem;

			.item {
				gap: 0rem;

				h1 {
					font-size: 1.5rem;
					margin-bottom: 0.25rem;
				}

				p, ul {
					font-size: 0.75rem;
				}

				.image {
					width: 2.5rem;

					img {
						width: 100%;
					}
				}
			}

			.line::after {
				content: none;
			}
		}
	}
}

</style>
