<template>
    <div class="link dropdowntop" @mouseenter="showItems" @mouseleave="hideItems">
        <div class="item-menu">
            <router-link :to="route">{{ title }}
                <img v-if="!isOpen" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Cima">
            </router-link>
        </div>
        <transition name="fade" appear="">
            <div class="item-sub-menu" v-if="isOpen">
                <div v-for="(item, i) in items" :key="i" class="refs">
                    <router-link :to="{ path: item.path, hash: item.hash }">{{ item.title }}</router-link>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'dropdowntopo',
    props: ['title', 'route', 'items'],
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        showItems() {
            this.isOpen = true
        },
        hideItems() {
            this.isOpen = false
        }
    }
}

</script>

<style lang="scss" scoped>

.dropdowntop {
    padding: 0.625rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    background-color: var(--amarelo);
    border-radius: 0.625rem;
    cursor: pointer;

    .item-menu {    
        padding: 0.25rem 0.625rem;
        display: flex;       
        align-items: center;
        justify-content: center;

        img {
            width: 1.25rem;
            height: 0.625rem;
        }
    }

    .item-sub-menu {
        font-size: 1rem;
        font-weight: 400;
        pointer-events: none;
        
        .refs {
            padding: 0.125rem 0rem;
            display: flex;

            a {
                flex-grow: 1;
            }
        }
    }

    a {
        color: var(--preto) ;
        text-decoration: none;
    }
}

.dropdowntop:hover {
    border: 2px solid var(--preto);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    // border-radius: 10px;
    padding: 8px;
}

</style>