<template>
    <div class="venha-conhecer">
        <div class="container">
            <h1>Venha conhecer nosso trabalho no Instagram💛</h1>
            <div class="box">
                <p>Postamos conteúdos sobre nossas saídas do mês, divulgamos campanhas, publicamos o Fluxo de Caixa mensal e discutimos sobre direitos sociais.</p>
            </div>
            <a v-if="isMobile" href="https://www.instagram.com/ongdarua/" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a v-else href="https://www.instagram.com/ongdarua/" target="_blank" rel="noopener noreferrer">Link para nosso Instagram</a>
            <div class="celular">
                <img :src="celular" alt="Instagram da Ong" />
            </div>
        </div>
        <div class="ongdarua">
            <img :src="ongdarua" alt="@ongdarua" />
        </div>
    </div>
</template>

<script>

import VueLazyload from "vue-lazyload";

export default {
    name: "venhaconhecer",
    props: ['isMobile'],
    data() {
        return {
            celular: require("../../assets/Home/celular.png"),
            ongdarua: require("../../assets/Home/ongdarua.png"),
        };
    },
    directives: {
        'lazy': VueLazyload.directive,
    },
}

</script>

<style lang="scss" scoped>

.venha-conhecer {
    background-color: var(--bege);
    // margin: auto;
    height: 32rem;
    // max-width: 90rem;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4rem;
    padding-left: 2rem;

    .container {  
        margin: auto;      
        display: grid;
        grid-template: "title box" "insta box";
        align-items: center;
        justify-items: start;
        gap: 2rem;
        position: relative;

        h1 {
            grid-area: title;
            width: 21rem;
            font-size: 2.5rem;
            font-weight: 800;
            line-height: 2.75rem;
        }

        .box {
            grid-area: box;
            width: 27rem;
            line-height: 1.5rem;
            background-color: var(--amarelo);
            padding: 1.5rem 11.5rem 1.5rem 1.5rem;
            border: 2px solid var(--preto);
            border-radius: 0.5rem;
        }

        a {
            grid-area: insta;
            background-color: var(--preto);
            color: var(--bege);
            text-decoration: none;
            border: solid 2px var(--preto);
            border-radius: 0.75rem;
            padding: 0.75rem 1rem;
            font-weight: 600;
            font-style: italic;
            white-space: nowrap;
        }
    
        .celular {
            z-index: 5;
            position: absolute;
            height: 33.5rem;
            left: 62%;

            img {
                height: 100%;
            }
        }
    }

    .ongdarua {
        overflow: hidden;
        position: relative;
        height: 100%;
        
        img {
            width: 20rem;
        }
    }

    @media (max-width: 64rem) {
        justify-content: space-around;

        .container {
            justify-self: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            h1 {
                width: 25rem;
                font-size: 2rem;
                line-height: 2rem;
            }

            .box {
                padding: 1.5rem 6.5rem 1.5rem 1.5rem;
            }

            .celular {
                top: -42%;    
                left: 50%;
            }
        }
    }

    @media (max-width: 48rem) {
        height: 20rem;
        padding-left: 1.5rem;
        justify-content: flex-end;
        border-bottom: 1px solid var(--preto);
        overflow: hidden;

        .container {
            justify-self: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;

            h1 {
                width: 16rem;
                font-size: 1.25rem;
                line-height: 1.5rem;
            }

            .box {
                width: 13.5rem;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1rem;
                padding: 0.5rem 1rem 0.5rem 0.5rem;
                border: 1px solid var(--preto);
            }

            a {
                font-size: 0.75rem;
                padding: 0.5rem;
            }

            .celular {
                top: 0;    
                left: 62%;
                height: 13.25rem;
            }
        }

        .ongdarua {
            img {
                width: 10rem;
            }
        }
    }
}

</style>