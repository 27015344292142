<template>
  <div class="menu-side">
    <router-link class="link" to="/">Home</router-link>
    <DropDownLateral title='Sobre nóis' route='/sobrenois' :items='sobrenois'/>
    <DropDownLateral title='Nóis e você' route='/noisvoce' :items='noisvoce'/>
    <DropDownLateral title='Nóis e a sociedade' route='/noissociedade' :items='noissociedade'/>
    <router-link class="link" to="/blog">Blog</router-link>
    <!-- <router-link class="link" to="/blog">Blog</router-link> -->
    <DoeAgora class="btn" />
    <p>"Tudo que nóis tem é nóis"</p>
  </div>
</template>

<script>

import DropDownLateral from './DropDownLateral.vue';
import DoeAgora from '../DoeAgora.vue'

export default {
    name: 'menulateral',
    components: {
        DropDownLateral,
        DoeAgora
    },
    data() {
        return {
            sobrenois: [
                {title: 'História', path: '/sobrenois', hash: '#historia'}, 
                {title: 'Frentes de ação', path: '/sobrenois', hash: '#frentesdeatuacao'},
                {title: 'Locais de ação', path: '/sobrenois', hash: '#locaisdeatuacao'}, 
                {title: 'Equipe', path: '/sobrenois', hash:'#equipe'},
                {title: 'Galeria de fotos', path: '/sobrenois', hash: '#galeria'}
            ],
            noisvoce: [
                {title: 'Como ajudar', path: '/noisvoce', hash: '#comoajudar'}, 
                {title: 'Contatos', path: '/noisvoce', hash: '#contatos'}
            ],
            noissociedade: [
                {title: 'Na mídia', path: '/noissociedade', hash: '#namidia'}, 
                {title: 'Parcerias', path: '/noissociedade', hash: '#parcerias'},
                // {title: 'Comentários de Parceiros', path: '/noissociedade', hash: '#comentariosparceiros'}
            ]
        }
    }
}
</script>

<style lang="scss">

.menu-side {
    background: var(--amarelo);
    z-index: 20;
    position: absolute;
    top: 100%;
    right: -2px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0px 20px 20px 20px;
    gap: 10px;
    font-size: 24px;
    font-weight: 600;
    border: 2px solid var(--preto);
    border-top: none;

    .link {
        color: var(--preto);
        text-decoration: none;
        padding-bottom: 10px;
        border-bottom: 2px solid var(--preto);
        cursor: pointer;
        // background: blue;
    }

    .btn {
        margin: 1rem;
        align-self: center;
    }
    
    p {
        align-self: center;
        text-align: center;
        font-size: 20px;
    }

    @media (max-width: 48rem) {
        border: 1px solid var(--preto);
        border-top: none;
        border-radius: 0;

        .btn {
            margin: 0rem;
            align-self: center;
        }
    }

    @media (max-width: 38rem) {
        width: calc(100% + 4px);
    }
}
</style>