<template>
    <div class="questions">    
        <div class="text-container">
            <h1>{{title}}</h1>
            <div class="grid-container">

                <div class="grid-item" @click="toggle(1)">
                    <div class="item-title">
                        <h2>O que fazemos?</h2>
                        <img v-if="!isOpen1" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen1">
                        <p>Procuramos <span>promover a dignidade e dar visibilidade às pessoas em situação de rua no Distrito Federal.</span></p>
                        <p>Queremos agir como uma ponte entre as pessoas que auxiliamos e empresas, representantes locais do governo e órgãos de assistência social.</p>
                        <p>Além disso, estudamos e conscientizamos nossa comunidade acerca de <span>pautas como desigualdades sociais e suas consequências culturais e econômicas no Brasil.</span></p>
                    </div>
                </div>

                <div class="grid-item" @click="toggle(2)">
                    <div class="item-title">
                        <h2>O que doamos?</h2>
                        <img v-if="!isOpen2" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen2">
                        <p>Fazemos doações de itens essenciais como: roupas e sapatos, acessórios, bolsas e mochilas, enxoval para bebês, agasalhos e peças de frio, marmitas e alimentos não perecíveis, brinquedos, livros e itens de higiene (papel higiênico, sabonete, absorvente e lâmina de barbear).</p>
                    </div>
                </div>

                <div class="grid-item" @click="toggle(3)">
                    <div class="item-title">
                        <h2>Como atuamos?</h2>
                        <img v-if="!isOpen3" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen3">
                        <p>Aos domingos, nossa equipe se reune pela manhã na casa de um membro para organizar os materiais da saída.</p>
                        <p>Organizamos roupas, montamos kits de alimento e higiene e preparamos marmitas para entregar logo em seguida.</p>
                    </div>         
                </div>

                <div class="grid-item" @click="toggle(4)">
                    <div class="item-title">
                        <h2>Onde atuamos?</h2>
                        <img v-if="!isOpen4" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen4">
                        <p>Auxiliamos pessoas em situação de rua e vulnerabilidade social localizadas no <span>Distrito Federal, principalmente no Plano Piloto.</span></p>
                    </div>              
                </div>

                <div class="grid-item" @click="toggle(5)">
                    <div class="item-title">
                        <h2>Por quê fazemos?</h2>
                        <img v-if="!isOpen5" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen5">
                        <p>Segundo o Padre Júlio Lancellotti, “Para ser solidário não precisa ser religioso, tem que ser humano”. Assim, <span>não nos vinculamos a nenhuma religião ou partido político</span>, visto que estamos exercendo nosso dever de cidadãos que querem viver em uma cidade mais justa e igualitária para todos.</p>
                        <p>Para nós, a miséria é uma violência. Frequentemente, presenciamos pessoas com fome, doentes, abusadas e dormindo em papelões. Percebemos que não precisa ir muito longe para presenciar atentados cotidianos aos direitos humanos. Resolvemos, então, <span>transformar privilégios em responsabilidades</span>.</p>
                    </div>             
                </div>

                <div class="grid-item" @click="toggle(6)">
                    <div class="item-title">
                        <h2>Quem participa?</h2>
                        <img v-if="!isOpen6" src="../../assets/Header/arrowDown.svg" alt="Seta para Baixo">
                        <img v-else src="../../assets/Header/arrowUp.svg" alt="Seta para Baixo">
                    </div>
                    <div class="item-text" v-if="isOpen6">
                        <p>Nossa equipe é formada por <span>jovens de 18 a 25 anos</span>, que se distribuem em 5 áreas de atuação dentro do projeto.</p>
                        <p>Realizamos processos seletivos semestralmente para que novas pessoas possam entrar na equipe.</p>
                    </div>
                    
                </div>
            </div>
        </div>
        <div v-if="!isMobile" class="image" :class="{active : isOpen1 || isOpen2 || isOpen3 || isOpen4 || isOpen5 || isOpen6}">
            <img :src="image" alt="Doações" />
        </div>
    </div>
</template>

<script>

import VueLazyload from "vue-lazyload";

export default {
    name: 'perguntasfrequentes',
    props: ['isMobile'],
    data() {
        return {
            title: 'Perguntas Frequentes',
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            image: require("../../assets/Home/perguntasFrequentes.jpg")
        }
    },
    directives: {
        'lazy': VueLazyload.directive,
    },
    methods: {
        toggle(index) {
            this['isOpen' + index] = !this['isOpen' + index];
            for (let i = 1; i <= 6; i++) {
                if (i !== index) {
                    this['isOpen' + i] = false;
                }
            }
        }
    }
}

</script>

<style lang="scss" scoped>

.questions {
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: 110rem;

    .text-container {
        width: 50%;
        padding: 3rem 0rem;

        h1 {
            max-width: 32rem;
            text-align: center;
            font-family: var(--font1);
            font-weight: 400;
            font-size: 3.5rem;
            text-transform: uppercase;
            line-height: 3.5rem;
            margin: auto;
            margin-bottom: 3rem;

            @media (max-width: 64rem) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
        }

        .grid-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            .grid-item {
                background: var(--cinza);
                border-radius: 0.5rem;
                border: 2px solid var(--preto);
                width: 80%;
                min-width: 14rem;
                max-width: 26rem;
                padding: 1rem 0.75rem;
                cursor: pointer;
                
                .item-title {    
                    display: flex;       
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;

                    h2 {
                        white-space: nowrap;
                        font-weight: 500;
                        font-size: 1.5rem;
                    }
                    
                    img {
                        width: 1.5rem;
                        height: 0.75rem;
                    }
                }

                .item-text {
                    p {  
                        text-align: start;
                        font-size: 1rem;
                        font-weight: 400;
                        padding: 0.25rem;
                    }

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .image {
        height: 46rem;
        width: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
            height: 74rem;
        }
    }

    .active {
        height: 64rem;
    }

    @media (max-width: 57.25rem) {
        .image img {
            height: 70rem;
        }
        .active {
            height: 70rem;
        }
    }

    @media (max-width: 48rem) {
        .text-container {
            width: 100%;
            padding: 2rem 0.5rem;
    
            h1 {
                font-size: 1.3rem;
                line-height: 1.5rem;
                margin-bottom: 2rem;
            }

            .grid-container .grid-item {
                width: 14rem;
                border: 1px solid var(--preto);

                .item-title {
                    h2 {
                        font-weight: 600;
                        font-size: 1rem;
                    }

                    img {
                        width: 1.5rem;
                        height: 0.75rem;
                    }
                }

                .item-text p {
                    font-size: 0.75rem;
                    font-weight: 400;
                }
            }
        }
    }
}

</style>